import React, {Component} from "react";
import {Badge, Button, Card, Container, Dropdown, DropdownButton, Form, Spinner} from "react-bootstrap";
import Header from "../../Fragments/Header";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import api from "../../../api";
import axios from "axios";
import Preloader from "../../Fragments/Preloader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import './Cart.sass';

const masterlicense = {
    '30': '43a75d7c-841e-49d7-892f-a23aaccd31ad',
    '50': '281689e0-b84e-4320-8b1e-17a9d367600c',
    '295': 'baf94f4b-5baa-4ff8-8e51-dd07f26056ba',
    '495': '5e868377-865f-4f70-ae31-f6d51fa79d01',
    '695': '82d084b5-acb5-4ec9-a77b-318723350c1b',
    '895': '6cec9e1d-b740-441c-95e0-9906931dfd9e',
}
const exclusiveLicense = {
    '2ac1e267-27ea-4262-916c-b8ce6bc32637': {
        name: '3-Month Exclusive',
        isExclusive: 1,
        price: 60
    },
    '3f73a9e4-8954-4d8f-9376-8ce18779a9fd': {
        name: '6-Month Exclusive',
        isExclusive: 1,
        price: 100
    },
    'efa4e66a-b6f0-427b-adbf-fdab2fa53539': {
        name: '1-Year Exclusive',
        isExclusive: 1,
        price: 150
    },
    'a1773a78-6a17-4b52-8abd-34da4620e80f': {
        name: 'Royalty Free',
        isRoyaltyFree: 1,
        price: 99
    },
}

class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            musicList: {},
            code: null,
            selectedItems: [],
            selectedProducts: [],
            loading: false,
            errorItems: [],
            checkoutLoading: false,
            selectedPrices: {
                discount: 0,
                price: 0
            }
        }
        this.header = React.createRef();
        this.form = React.createRef();
    }

    componentDidMount() {
        document.title = "My Cart"
        if (this.props.state.auth.user_id) {
            this.setState({
                loading: true
            })
            Promise.all([
                axios.request({
                    url: `${api.Cart.Get.url}${this.props.state.auth.user_id}`,
                    method: api.Cart.Get.method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'cache-control': 'no-cache',
                        'Authorization': this.props.state.auth.access_token
                    }
                }).then(resp => resp.data),
                axios.request({
                    url: `${api.User.Get.url}${this.props.state.auth.user_id}`,
                    method: api.User.Get.method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'cache-control': 'no-cache',
                        'Authorization': this.props.state.auth.access_token
                    }
                }).then(response => response.data),
                axios.request({
                    url: `${api.Cart.Check.url}${this.props.state.auth.user_id}/check`,
                    method: api.Cart.Check.method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': this.props.state.auth.access_token
                    }
                }).then(resp => resp.data)
            ]).then(responses => {
                this.props.onUpdateProfile(responses[1]);

                let callableItems = [];
                responses[0].cartItems.forEach(e => {
                    let songId = e.product.songId;
                    if (!callableItems.includes(songId)) {
                        callableItems.push(songId)
                    }

                })
                this.callableItems = {}
                Promise.all(callableItems.map(e => axios.request({
                    url: `${api.Song.url}${e}/hasExclusiveAvailable`,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'cache-control': 'no-cache'
                    },
                    method: api.Song.method,
                }).then(response => response.data))).then(ExclusiveResponses => {
                    ExclusiveResponses.forEach((e,key)=>{

                        this.callableItems[callableItems[key]] = e
                    })

                    responses[0].cartItems = responses[0].cartItems.map(cartItem => {
                        cartItem.disableExclusive = !ExclusiveResponses[callableItems.findIndex(u => u === cartItem.product.songId)]
                        return cartItem
                    })
                    this.props.onCartFetch(responses[0]);
                    this.setState({
                        loading: false,
                        errorItems: responses[2]
                    })

                })

            }).catch(() => {
                this.props.Toast({
                    type: 'primary',
                    message: 'Something went wrong'
                })
            })
        }

    }
    handleFilterResponse = (resp)=>{
        resp.cartItems = resp.cartItems.map(cartItem => {
            cartItem.disableExclusive = !this.callableItems[cartItem.product.songId]
            return cartItem
        })
    }

    removeCart = (cartData, licenseId) => {
        if (this.props.state.auth.user_id) {
            this.setState({
                loading: true
            })
            if (licenseId) {
                const data = {
                    UserId: this.props.state.auth.user_id,
                    ProductId: cartData.product.songId,
                    LicenseId: licenseId
                }


                return axios.request({
                    url: api.Cart.Remove.url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': this.props.state.auth.access_token
                    },
                    method: api.Cart.Remove.method,
                    data: JSON.stringify(data),
                }).then(e => {
                    let OldCart = {...this.props.state.cart};
                    OldCart.cartItems = OldCart.cartItems.filter(j=>!(j.product.songId===cartData.product.songId && j.license.licenseId===licenseId))
                    this.handleFilterResponse(OldCart);
                    this.props.onCartFetch(OldCart)
                    this.setState({
                        loading: false
                    })
                })
            }
            if (cartData.licenses && Object.keys(cartData.licenses).length) {

                return Promise.all(Object.keys(cartData.licenses).map(e => {
                    const data = {
                        UserId: this.props.state.auth.user_id,
                        ProductId: cartData.product.songId,
                        LicenseId: e
                    }

                    return axios.request({
                        url: api.Cart.Remove.url,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': this.props.state.auth.access_token
                        },
                        method: api.Cart.Remove.method,
                        data: JSON.stringify(data),
                    })
                })).then(u => {
                    let OldCart = {...this.props.state.cart};
                    OldCart.cartItems = OldCart.cartItems.filter(j=>!(j.product.songId===cartData.product.songId && Object.keys(cartData.licenses).includes(j.license.licenseId)))
                    this.handleFilterResponse(OldCart);
                    this.props.onCartFetch(OldCart)
                    this.setState({
                        loading: false
                    })
                })

            }

            const data = {
                UserId: this.props.state.auth.user_id,
                ProductId: cartData.product.songId,
                LicenseId: cartData.license.licenseId
            }

            return axios.request({
                url: api.Cart.Remove.url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.state.auth.access_token
                },
                method: api.Cart.Remove.method,
                data: JSON.stringify(data),
            }).then(response => response.data).then(response => {
                if (this.state.cartCalculator) {
                    clearTimeout(this.state.cartCalculator)
                }
                let OldCart = {...this.props.state.cart};
                OldCart.cartItems = OldCart.cartItems.filter(e=>!(e.product.songId===cartData.product.songId && e.license.licenseId===cartData.license.licenseId))
                this.handleFilterResponse(OldCart);
                this.props.onCartFetch(OldCart)

                this.handleFilterResponse(response);
                this.props.onCartFetch(response)
                this.setState({
                    loading: false,
                    cartCalculator: setTimeout(() => this.onSelectItem(), 1000),
                    priceLoading: true
                })


            }).catch(() => {
                this.props.Toast({
                    type: 'primary',
                    message: 'Something went wrong'
                })
            })
        } else {
            this.props.onCartRemove(cartData)
            if(cartData.royalty){
                this.props.onCartRemove(cartData.royalty)
            }
            if (cartData.exclusivity) {
                this.props.onCartRemove(cartData.exclusivity)
            }

        }

    }
    removeCoupon = () => {
        if (this.state.error) {
            this.setState({
                error: false
            })
        } else {
            axios.request({
                url: `${api.Coupon.Remove.url}/${this.props.state.auth.user_id}`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.state.auth.access_token
                },
                method: api.Coupon.Remove.method,
            }).then(response => {
                if (response.status === 200) {

                    this.props.onRemoveCoupon();
                }
            }).catch(() => {
                this.props.Toast({
                    type: 'primary',
                    message: 'Something went wrong'
                })
            }).finally(this.onSelectItem)
        }

    }
    addCoupon = () => {
        if (this.props.state.auth.user_id) {
            const data = JSON.stringify({
                UserId: this.props.state.auth.user_id,
                Code: this.state.code
            })
            axios.request({
                url: api.Coupon.Add.url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.state.auth.access_token
                },
                method: api.Coupon.Add.method,
                data: data,
            }).then(response => response.data).then(response => {
                if (response.coupon) {
                    this.onSelectItem()
                    let callableItems = [];
                    response.cartItems.forEach(e => {
                        let songId = e.product.songId;
                        if (!callableItems.includes(songId)) {
                            callableItems.push(songId)
                        }

                    })
                    Promise.all(callableItems.map(e => axios.request({
                        url: `${api.Song.url}${e}/hasExclusiveAvailable`,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'cache-control': 'no-cache'
                        },
                        method: api.Song.method,
                    }).then(response => response.data))).then(ExclusiveResponses => {
                        response.cartItems = response.cartItems.map(cartItem => {
                            cartItem.disableExclusive = !ExclusiveResponses[callableItems.findIndex(u => u === cartItem.product.songId)]
                            return cartItem
                        })
                        this.props.onCartFetch(response);
                        this.setState({
                            error: null
                        })


                    })

                } else {
                    this.setState({
                        error: 'That code is invalid'
                    })
                }


            }).catch(() => {
                this.props.Toast({
                    type: 'primary',
                    message: 'Something went wrong'
                })
            })
        } else {
            this.header.current.navOpen();
        }
    }
    doCheckout = () => {
        if (this.props.state.auth.user_id) {
            if (!this.props.state.auth.user.verified) {
                this.props.Toast({
                    type: 'cart-message',
                    message: 'Please check your email and verify your Rocketsongs account to proceed.'
                })
                return;
            }
            this.props.addSelected(this.state.selectedItems)
            return this.props.history.push('/checkout')

        } else {
            this.header.current.navOpen();
        }

    }
    addToCart = (song, license, licenseData) => {
        if (Object.keys(exclusiveLicense).includes(license)) {
            licenseData = exclusiveLicense[license]
        }

        const cartData = {
            UserId: this.props.state.auth.user_id,
            items: [
                {
                    ProductId: song.songId,
                    LicenseId: license,
                },
            ]
        };
        if (this.props.state.auth.user_id) {
            this.setState({
                loading: true
            })
            axios.request({
                url: api.Cart.Add.url,
                method: api.Cart.Add.method,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': this.props.state.auth.access_token
                },
                data: JSON.stringify(cartData),
            }).then(response => response.data).then(response => {
                this.handleFilterResponse(response);
                this.props.onCartFetch(response);

            }).catch(err => {
                this.props.Toast({
                    type: 'primary',
                    message: 'Something went wrong'
                })
            }).finally(() => {
                if (this.state.cartCalculator) {
                    clearTimeout(this.state.cartCalculator)
                }
                this.setState({
                    loading: false,
                    cartCalculator: setTimeout(() => this.onSelectItem(), 1000),
                    priceLoading: true
                })
            })
        } else {
            const cartItem = {
                product: song,
                ProductId: song.songId,
                LicenseId: license,
                license: {
                    name: licenseData.name,
                    isExclusive: licenseData.isExclusive,
                    isRoyaltyFree: licenseData.isRoyaltyFree,
                    licenseId: license
                },
                price: parseInt(licenseData.price)
            }
            this.props.onAddtoCart(cartItem)
        }
    }
    onSelectItem = () => {
        let CartItems = this.props.state.cart.cartItems
        let selectedItems = this.state.selectedItems;
        let selectedProducts = []
        if (this.form.current) {
            if (this.form.current.elements.selectedProducts.length > 1) {
                this.form.current.elements.selectedProducts.forEach(item => {
                    if (item.checked) {
                        selectedProducts.push(item.value)
                    }
                })
            } else {
                if (this.form.current.elements.selectedProducts.checked) {
                    selectedProducts.push(this.form.current.elements.selectedProducts.value)
                }
            }
        }
        let filteredItems = CartItems.filter(item => selectedProducts.includes(item.product.songId))
        selectedItems.length = 0;
        filteredItems.forEach(item => {
            selectedItems.push(item.shoppingCartItemId)
        })
        let cartData = ''
        let lastElement = selectedItems.length - 1
        selectedItems.forEach((item, key) => {
            cartData = cartData + 'shoppingCartItemIds=' + item + (key !== lastElement ? '&' : '')
        })
        this.setState({
            selectedItems,
            priceLoading: true
        })
        axios.request({
            url: api.Cart.Get.url + this.props.state.auth.user_id + '/discount?' + cartData,
            method: api.Cart.Get.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'cache-control': 'no-cache',
                'Authorization': this.props.state.auth.access_token
            },
        }).then(response => {
            let newCartData = response.data
            let newTotalPrice = 0;
            let newDiscount = this.props.state.cart.coupon ? newCartData.coupon.discount : 0;

            newCartData.cartItems.forEach(item => {
                newTotalPrice += item.price;
            })
            this.setState({
                selectedPrices: {
                    price: newTotalPrice,
                    discount: newDiscount
                },
                priceLoading: false
            })
        })

    }
    handleCartChange = (cart, license, licenseData) => {
        if (license === 'remove-exclusivity') {
            if (cart.exclusivity) {
                delete cart.exclusivity.licenses;
                this.removeCart(cart.exclusivity);
                delete cart.exclusivity;
            }

            return
        }
        if (license === 'royalty') {
            if (licenseData) {
                licenseData = exclusiveLicense['a1773a78-6a17-4b52-8abd-34da4620e80f'];
                this.addToCart(cart.product, 'a1773a78-6a17-4b52-8abd-34da4620e80f', licenseData)
            } else {
                this.removeCart(cart.royalty)
                delete cart.royalty;
            }

            return
        }
        if (!Object.values(masterlicense).includes(license)) {
            if (cart.exclusivity) {
                delete cart.exclusivity.licenses;
                this.removeCart(cart.exclusivity)
            }
        } else {
            let mainLicense = Object.keys(cart.licenses).find(e => Object.values(masterlicense).includes(e));
            if (mainLicense) {
                this.removeCart(cart, mainLicense)
            }

        }
        this.addToCart(cart.product, license, licenseData)
    }
    handlePushLicense = (item, productItem) => {
        if (!item.licenses) {
            item.licenses = {[productItem.license.licenseId]: productItem.price}
        } else {
            if (!Object.keys(item.licenses).includes(productItem.license.licenseId)) {
                item.licenses[productItem.license.licenseId] = productItem.price
            }

        }
    }

    render() {
        let cartData;
        if (this.props.state.auth.user_id) {
            cartData = this.props.state.cart.cartItems || []
        } else {
            cartData = this.props.state.cart.Items || []
        }
        let price = 0;
        let cartItems = [];
        if (cartData && cartData.length) {

            cartData.forEach((item) => {
                let index = cartItems.findIndex(cartItem => cartItem.product.songId === item.product.songId)
                if (index >= 0) {
                    this.handlePushLicense(cartItems[index], item)
                    if (item.license.isExclusive && !Object.values(masterlicense).includes(item.license.licenseId)) {
                        cartItems[index].exclusivity = {
                            ...item,
                            exclusivity: null,
                            licenses: null,
                            royalty: null
                        }
                    }
                    if (item.license.isRoyaltyFree) {
                        cartItems[index].royalty = {
                            ...item,
                            exclusivity: null,
                            licenses: null,
                            royalty: null
                        }
                    }
                } else {
                    let mainProductIndex = cartData.findIndex(cartItem => cartItem.product.songId === item.product.songId && Object.values(masterlicense).includes(cartItem.license.licenseId))
                    if (mainProductIndex >= 0) {
                        cartData[mainProductIndex].licenses = {[item.license.licenseId]: item.price}
                        if (item.license.isExclusive && !Object.values(masterlicense).includes(item.license.licenseId)) {
                            cartData[mainProductIndex].exclusivity = {
                                ...item,
                                exclusivity: null,
                                licenses: null,
                                royalty: null
                            }
                        }
                        if (item.license.isRoyaltyFree) {
                            cartData[mainProductIndex].royalty = {
                                ...item,
                                exclusivity: null,
                                licenses: null,
                                royalty: null
                            }
                        }
                        cartItems.push(cartData[mainProductIndex])
                    } else {
                        item.licenses = {[item.license.licenseId]: item.price}
                        if (item.license.isExclusive && !Object.values(masterlicense).includes(item.license.licenseId)) {
                            item.exclusivity = {
                                ...item,
                                exclusivity: null,
                                licenses: null,
                                royalty: null
                            }
                        }
                        if (item.license.isRoyaltyFree) {
                            item.royalty = {
                                ...item,
                                exclusivity: null,
                                licenses: null,
                                royalty: null
                            }
                        }
                        cartItems.push(item)
                    }

                }
                price = price + parseFloat(item.price)
            })

            if (this.props.state.cart.coupon) {
                price = price - parseFloat(this.props.state.cart.coupon.discount)
            }
        }
        cartData = cartItems;
        price = price.toFixed(2)
        return (
            <div className={"cart-container"}>
                <Preloader display={this.state.loading ? 'block' : 'none'}/>
                <Header ref={this.header} title={'Shopping Cart'}/>

                {!cartData.length ? <Container style={{padding: '70px 0', minHeight: '520px'}}>
                        <div style={{zIndex: 5, position: 'absolute'}}>You have no Items in your cart. <Link to="/shop">Click
                            here to shop now!</Link></div>
                    </Container> :
                    <section className={'content-section'}>
                        <div style={{padding: '20px 0'}}>
                            <Card className={'Cart-table-header-container'}>
                                <Card.Body className={'Cart-table-header'}>
                                    <div className={'details-col'}>Details</div>
                                    <div className={'license-col'}>Product</div>
                                    <div className={'license-col'}>Exclusive</div>
                                    <div className={'price-col'}>Royalty Free</div>
                                    <div className={'price-col'}>Discount</div>
                                    <div className={'price-col'}>Price</div>

                                </Card.Body>
                            </Card>
                            <form ref={this.form} onSubmit={e => e.preventDefault()}>
                                {cartData ? cartData.map((cart, Key) => {
                                    return <div key={Key} className={'single-cart-row-container'}>
                                        {this.props.state.auth.user && <div
                                            className="cart-process-checkbox custom-control custom-checkbox checkbox-lg">
                                            <input name={'selectedProducts'} onChange={this.onSelectItem}
                                                   value={cart.product.songId} type="checkbox"
                                                   className="custom-control-input"
                                                   id={"checkbox-" + cart.product.songId}/>
                                            <label className="custom-control-label"
                                                   htmlFor={"checkbox-" + cart.product.songId}/>
                                        </div>}
                                        <Card
                                            className={'single-song-card' + (this.state.selectedItems.includes(cart.product.songId) ? ' checked-item' : '') + (this.state.errorItems.includes(cart.product.songId) ? ' error-cart-item' : '')}>
                                            <Card.Body>
                                                <div className={'single-song-list'}>
                                                    <div className={'play-button-col'} id={cart.product.songId}
                                                         onClick={(e) => {
                                                             if (e.currentTarget.classList.contains('current-playing')) {
                                                                 e.currentTarget.classList.remove('current-playing');
                                                                 this.props.Stop();
                                                                 return;
                                                             } else if (document.getElementsByClassName('current-playing')[0]) {
                                                                 document.getElementsByClassName('current-playing')[0].classList.remove('current-playing')
                                                             }
                                                             e.currentTarget.classList.add('current-playing')
                                                             this.props.Play(cart.product)
                                                         }}/>
                                                    <div className={'cart-title-col'}>
                                                        <div><Link
                                                            onClick={() => window.scrollTo({
                                                                top: 0,
                                                                behavior: 'smooth'
                                                            })}
                                                            className={'song-title'}
                                                            to={`/TrackOwners/${encodeURIComponent(cart.product.owner.name)}/Songs/${cart.product.songId}/${encodeURIComponent(cart.product.title.replace('.', '_'))}`}>{cart.product.title}</Link>{cart.product.isRecent ?
                                                            <Badge className={'new-song-badge'}
                                                                   variant="primary">NEW</Badge> : null}</div>
                                                        <div>
                                                            <span className={'song-genres'}>{cart.product.genres}</span>
                                                        </div>

                                                    </div>
                                                    <div className={'license-dropdown-row song'}>
                                                        <div className={'hidden-titles'}>Product</div>
                                                        <DropdownButton className={'song-select-drop'}
                                                                        disabled={(cart.royalty || cart.exclusivity) && !Object.keys(cart.licenses).find(e => ['43a75d7c-841e-49d7-892f-a23aaccd31ad', '281689e0-b84e-4320-8b1e-17a9d367600c', masterlicense[cart.product.master]].includes(e))}
                                                                        title={Object.keys(cart.licenses).find(e => ['43a75d7c-841e-49d7-892f-a23aaccd31ad', '281689e0-b84e-4320-8b1e-17a9d367600c'].includes(e)) ?
                                                                            <span className={'drop-item-container'}>
                                                        <span
                                                            className={'song-comp-m-container' + (cart.product.mp3InstrumentalClip ? ' has' : '')}>
                                                            <div className={'song-comp-i'}/>
                                                        </span>
                                                        <span className={'drop-text-container'}><div style={{
                                                            'display': 'inline-block',
                                                            minWidth: '119px'
                                                        }}> {cart.product.mp3InstrumentalClip ? 'SONG+TRACK' : 'SONG'} </div><span
                                                            className={'price-container'}>${cart.product.hasWavInstrumental && !cart.product.isAllInstrumentalsDeleted  ? '60' : cart.product.mp3InstrumentalClip ? '50' : '30'}</span></span></span> :

                                                                            Object.keys(cart.licenses).find(e => Object.values(masterlicense).includes(e)) ?
                                                                                <span
                                                                                    className={'drop-item-container has'}>
                                                            <span className={'stems-mask-container'}>
                                                                <div className={'stems-icon'}/>
                                                            </span>
                                                            <span className={'drop-text-container'}><div style={{
                                                                'display': 'inline-block',
                                                                minWidth: '119px'
                                                            }}> STEMS</div>  <span
                                                                className={'price-container'}>${cart.product.master}</span></span></span> : null}>
                                                            <Dropdown.Item
                                                                onClick={() => this.handleCartChange(cart, cart.product.mp3InstrumentalClip ? '281689e0-b84e-4320-8b1e-17a9d367600c' : '43a75d7c-841e-49d7-892f-a23aaccd31ad', {
                                                                    name: 'STANDARD LICENSE',
                                                                    price: cart.product.hasWavInstrumental && !cart.product.isAllInstrumentalsDeleted  ? '60' : cart.product.mp3InstrumentalClip ? '50' : '30'
                                                                })} as={"button"}><span
                                                                className={'drop-item-container'}>
                                                            <span
                                                                className={'song-comp-m-container' + (cart.product.mp3InstrumentalClip ? ' has' : '')}>
                                                                <div className={'song-comp-i'}/>
                                                            </span>
                                                            <span className={'drop-text-container'}><div style={{
                                                                'display': 'inline-block',
                                                                minWidth: '119px'
                                                            }}>{cart.product.mp3InstrumentalClip ? 'SONG+TRACK' : 'SONG'}</div> <span
                                                                className={'price-container'}>${cart.product.hasWavInstrumental && !cart.product.isAllInstrumentalsDeleted  ? '60' : cart.product.mp3InstrumentalClip ? '50' : '30'}</span></span></span></Dropdown.Item>
                                                            {cart.product.master ? <Dropdown.Item
                                                                onClick={() => this.handleCartChange(cart, masterlicense[cart.product.master], {
                                                                    name: 'MASTER USE LICENSING',
                                                                    price: cart.product.master
                                                                })} as={"button"}><span
                                                                className={'drop-item-container has'}>
                                                            <span className={'stems-mask-container'}>
                                                                <div className={'stems-icon'}/>
                                                            </span>
                                                            <span className={'drop-text-container'}><div style={{
                                                                'display': 'inline-block',
                                                                minWidth: '119px'
                                                            }}> STEMS</div>  <span
                                                                className={'price-container'}>${cart.product.master}</span></span></span>
                                                            </Dropdown.Item> : null}
                                                        </DropdownButton>
                                                    </div>
                                                    <div className={'license-dropdown-row exclusivity'}>
                                                        <div className={'hidden-titles'}>Exclusive</div>
                                                        <DropdownButton className={'exclusivity-select-drop'}
                                                                        disabled={cart.disableExclusive} title={
                                                            Object.keys(cart.licenses).includes('3f73a9e4-8954-4d8f-9376-8ce18779a9fd') ?
                                                                <span className={'drop-item-container'}>
                                                            <span className={'exclusive-m-container'}>
                                                                <div className={'exclusive-icon exclusive-icon-small'}/>
                                                            </span>
                                                            <span className={'drop-text-container'}>$100 <span
                                                                className={'month-div'}> / 6 months</span></span>
                                                        </span> : Object.keys(cart.licenses).includes('efa4e66a-b6f0-427b-adbf-fdab2fa53539') ?
                                                                    <span className={'drop-item-container'}>
                                                        <span className={'exclusive-m-container'}>
                                                            <div className={'exclusive-icon exclusive-icon-small'}/>
                                                        </span>
                                                        <span className={'drop-text-container'}>$150 <span
                                                            className={'month-div'}> / 12 months</span></span>
                                                            </span> : Object.keys(cart.licenses).includes('2ac1e267-27ea-4262-916c-b8ce6bc32637') ?
                                                                        <span className={'drop-item-container'}>
                                                            <span className={'exclusive-m-container'}>
                                                                <div className={'exclusive-icon exclusive-icon-small'}/>
                                                            </span>
                                                            <span className={'drop-text-container'}>$60 <span
                                                                className={'month-div'}> / 3 months</span></span>
                                                        </span> :
                                                                        <span
                                                                            className={'drop-text-container small-font-drop'}>Please Select</span>}>
                                                            <Dropdown.Item
                                                                onClick={() => this.handleCartChange(cart, 'remove-exclusivity')}
                                                                as={"button"}>
                                                            <span style={{'padding-left': '10px'}}
                                                                  className={'drop-text-container small-font-drop'}>None</span>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={() => this.handleCartChange(cart, '2ac1e267-27ea-4262-916c-b8ce6bc32637', exclusiveLicense['2ac1e267-27ea-4262-916c-b8ce6bc32637'])}
                                                                as={"button"}> <span className={'drop-item-container'}>
                                                            <span className={'exclusive-m-container'}>
                                                                <div className={'exclusive-icon exclusive-icon-small'}/>
                                                            </span>
                                                            <span className={'drop-text-container'}>$60 <span
                                                                className={'month-div'}> / 3 months</span></span>
                                                        </span>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={() => this.handleCartChange(cart, '3f73a9e4-8954-4d8f-9376-8ce18779a9fd', exclusiveLicense['3f73a9e4-8954-4d8f-9376-8ce18779a9fd'])}
                                                                as={"button"}><span className={'drop-item-container'}>
                                                        <span className={'exclusive-m-container'}>
                                                            <div className={'exclusive-icon exclusive-icon-small'}/>
                                                        </span>
                                                        <span className={'drop-text-container'}>$100 <span
                                                            className={'month-div'}> / 6 months</span></span></span></Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={() => this.handleCartChange(cart, 'efa4e66a-b6f0-427b-adbf-fdab2fa53539', exclusiveLicense['efa4e66a-b6f0-427b-adbf-fdab2fa53539'])}
                                                                as={"button"}>
                                                            <span className={'drop-item-container'}>
                                                        <span className={'exclusive-m-container'}>
                                                            <div className={'exclusive-icon exclusive-icon-small'}/>
                                                        </span>
                                                        <span className={'drop-text-container'}>$150 <span
                                                            className={'month-div'}> / 12 months</span></span>
                                                            </span>
                                                            </Dropdown.Item>
                                                        </DropdownButton>
                                                    </div>
                                                    <div className={'price-row'}>
                                                        <div className={'hidden-titles'}>Royalty Free</div>
                                                         <div       style={{opacity:cart.product.hasRoyaltyFree?1:0}} className={'cart-royalty-container'}>
                                                            <input

                                                                disabled={!cart.product.hasRoyaltyFree || (this.props.state.auth.access_token && (!cart.royalty && !cart.royaltyFreeIsAvailable)) || (cart.royalty && !cart.exclusivity && cart.license.isRoyaltyFree === true)}
                                                                name={'royalty'}
                                                                onChange={(e) => this.handleCartChange(cart, 'royalty', e.target.checked)}
                                                                checked={!!cart.royalty} type="checkbox"
                                                                className={'scaled-checkbox'}

                                                            />
                                                             <div >$99</div>
                                                         </div>

                                                    </div>
                                                    <div className={'price-row'}>
                                                  <span>
                                                      <div className={'hidden-titles'}>Discount</div>
                                                      {cart.discount ? '$' + cart.discount : 'N/A'}

                                                  </span>
                                                    </div>
                                                    <div className={'price-row'}>
                                                        <div className={'hidden-titles'}>Price</div>

                                                        ${(Object.values(cart.licenses).reduce((accumulator, currentValue) => accumulator + currentValue, 0))}
                                                    </div>

                                                    <div className={'delete-col standard-col '}
                                                         onClick={() => this.removeCart(cart)}>
                                                        <div className={'delete-mask-container'}>
                                                            <div className={'delete-icon'}/>
                                                        </div>
                                                        DELETE
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                }) : null}
                            </form>
                        </div>
                    </section>}
                <div className={'fixed-cart-details-container'}>
                    <div
                        className={'coupon-container ' + (this.props.state.cart.coupon ? 'coupon-code-activated' : '')}>
                        <div className={'coupon-input'}>
                            {this.props.state.cart.coupon ? <div className={'coupon-code'}>Active
                                    coupon:<span>{this.props.state.cart.coupon.code}</span></div> :
                                <Form.Group className={'profile-input-labels'}
                                            controlId="title">
                                    <Form.Label>Coupon Number {this.state.error &&
                                        <span className={'error-coupon'}>({this.state.error})</span>}</Form.Label>
                                    <Form.Control
                                        onChange={(e) => this.setState({code: e.target.value})}
                                        value={this.state.code}
                                        name={'title'}
                                    />
                                </Form.Group>}

                        </div>
                        {this.props.state.cart.coupon ?
                            <div className={'coupon-add-button coupon-remove-button'}>
                                <button onClick={this.removeCoupon}><FontAwesomeIcon icon={faMinus}/></button>
                            </div>
                            :
                            <div className={'coupon-add-button'}>
                                <button onClick={this.addCoupon}><FontAwesomeIcon icon={faPlus}/></button>
                            </div>}
                    </div>
                    <div className={'price no-price'}>
                        <p>Subtotal</p>
                        {this.state.priceLoading ? <Spinner animation="border"/> : <div
                            className={this.props.state.cart.coupon && ((this.state.selectedPrices.discount && this.state.selectedPrices.price) || (!this.state.selectedPrices.price && this.props.state.cart.coupon.discount)) ? 'scratched' : ''}>${this.state.selectedPrices.price ? this.state.selectedPrices.price : parseFloat(price) + (this.props.state.cart.coupon ? this.props.state.cart.coupon.discount : 0)}</div>}
                    </div>
                    <div className={'price discount-price'}>
                        <p>Discount</p>
                        {this.state.priceLoading ? <Spinner animation="border"/> :
                            <div>${this.state.selectedPrices.discount || this.state.selectedPrices.price ? this.state.selectedPrices.discount : this.props.state.cart.coupon ? this.props.state.cart.coupon.discount : 0}</div>}
                    </div>
                    <div className={'price total-pay'}>
                        <p>Total</p>
                        {this.state.priceLoading ? <Spinner animation="border"/> :
                            <div>${this.state.selectedPrices.price ? this.state.selectedPrices.price - this.state.selectedPrices.discount : parseFloat(price)}</div>}
                    </div>
                    <div className={'checkout-button'}>
                        <Button disabled={this.state.errorItems.length} onClick={this.doCheckout}
                                className={'find-button'}>{this.state.checkoutLoading ?
                            <Spinner animation="border"/> : 'CHECKOUT'}</Button>
                    </div>
                    <div className={'continue-shop-container'}>
                        <Link to={'/shop'}>Continue Shopping</Link>
                    </div>

                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
        onAddtoCart: (cartData) => {
            dispatch({
                type: "ADD_TO_CART",
                payload: cartData
            })
        },
        onCartFetch: (cart) => {
            dispatch({
                type: "FETCH_CART",
                payload: cart
            })
        },
        addSelected: (cart) => {
            dispatch({
                type: "ADD_SELECTED_ITEMS",
                payload: cart
            })
        },
        onCartRemove: (item) => {
            dispatch({
                type: "REMOVE_FROM_CART",
                payload: item
            })
        },
        onRemoveCoupon: () => {
            dispatch({
                type: "REMOVE_COUPON",
            })
        },
        onUpdateProfile: (userData) => {
            dispatch({
                type: "UPDATE_PROFILE",
                payload: userData
            })
        },
        Play: (music) => {
            dispatch({
                type: "PLAY",
                payload: music
            })
        },
        Stop: () => {
            dispatch({
                type: "STOP",
            })
        },
        Toast: (data) => {
            dispatch({
                type: "NOTIFICATE",
                payload: data
            })
        },

    }))(withRouter(Cart));
