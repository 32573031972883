import React, {useEffect, useRef, useState} from 'react'
import Header from "../../Fragments/Header";
import {
    PayPalHostedField, PayPalHostedFieldsProvider, PayPalScriptProvider, usePayPalHostedFields
} from "@paypal/react-paypal-js";
import axios from "axios";
import api from "../../../api";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Spinner} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import PaypalButton from "../../../../src/icons/paypal.svg"
import Footer from "../../Fragments/Footer";
import countryRegionData from "./countries.json";
import Select from "react-select";

const CUSTOM_FIELD_STYLE = {
    "fontSize": "1rem",
    "border": '1px solid #ced4da',
    "borderRadius": '.25rem',
    padding: "0.375rem 0.75rem",
    outline: 'none',
    height: 'calc(1.5em + .75rem + 15px)'
};


const SubmitPayment = ({ customStyle, showBilling }) => {
    const [paying, setPaying] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [regions, setRegions] = useState([]);
    const cardHolderName = useRef(null);
    const hostedField = usePayPalHostedFields();
    const dispatch = useDispatch()
    const token = useSelector((state) => state.auth?.access_token);
    const state = useSelector((state) => state);
    const history = useHistory();



    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption.value);
        const country = countryRegionData.find((c) => c.countryShortCode === selectedOption.value);
        setRegions(country ? country.regions : []);
        setSelectedState(""); // Reset state selection
    };

    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption.value);
    };

    const handleClick = () => {

        if (!hostedField?.cardFields) {
            const childErrorMessage = "Unable to find any child components in the <PayPalHostedFieldsProvider />";
            throw new Error(childErrorMessage);
        }

        if (showBilling) {
            const billingAddressLine1 = document.getElementById("billing-address-line1").value;
            const billingCity = document.getElementById("billing-city").value;
            const billingPostalCode = document.getElementById("billing-postal-code").value;

            if (!billingAddressLine1 || !billingCity || !billingPostalCode || !selectedCountry) {
                setPaying(false);
                return  dispatch({
                    type: "NOTIFICATE", payload: {
                        type: 'danger', message: 'Please fill out all the required fields.'
                    }
                });

            }
        }

        const isFormInvalid =
            Object.values(hostedField.cardFields.getState().fields).some((field) => !field.isValid) ||
            !cardHolderName?.current?.value;

        if (isFormInvalid) {
            setPaying(false);
            return  dispatch({
                type: "NOTIFICATE", payload: {
                    type: 'danger', message: 'The credit card information is invalid.'
                }
            });

        }

        setPaying(true);

        hostedField.cardFields
            .submit({
                cardholderName: cardHolderName?.current?.value,
                billing_address: showBilling
                    ? {
                        address_line_1: document.getElementById("billing-address-line1").value,
                        address_line_2: document.getElementById("billing-address-line2").value || "",
                        admin_area_2: selectedState,
                        admin_area_1: document.getElementById("billing-city").value,
                        country_code: selectedCountry,
                        postal_code: document.getElementById("billing-postal-code").value,
                    }
                    : null,
            })
            .then((data) => {
                const { liabilityShift, orderId } = data;
                if (liabilityShift === "NO") {
                    setPaying(false);
                    return  dispatch({
                        type: "NOTIFICATE", payload: {
                            type: 'danger', message: 'Authentication failed. Payment cannot be processed.'
                        }
                    });
                }
                axios
                    .request({
                        url:api.PayPal.capture.url,
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: token,
                        },
                        method: api.PayPal.capture.method,
                        data: {
                            UserId: state.auth.user_id,
                            ShoppingCartId: state.cart.shoppingCartId,
                            ShoppingCartItemIds: state.cart.selectedItems,
                            orderId,
                            liabilityShift,
                        },
                    })
                    .then(() => {
                        history.push("/Shop/Thanks/" + orderId + "/payed");
                    })
                    .finally(() => {
                        setPaying(false);
                    });
            })
            .catch(() => {
                setPaying(false);
            });
    };

    return (
        <>
            <div className="card-inputs-container">
                <div style={{ marginTop: 10 }}>
                    <input
                        id="card-holder"
                        ref={cardHolderName}
                        className="card-field"
                        style={{ ...customStyle, width: "100%" }}
                        type="text"
                        placeholder="Full name"
                    />
                </div>
            </div>
            {showBilling && (
                <div className="card-inputs-container">
                    <div className="billing-row">
                        <div style={{ marginTop: 10 }}>
                            <input
                                type="text"
                                id="billing-address-line1"
                                className="billing-field"
                                style={customStyle}
                                placeholder="Address Line 1"
                                required={true}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <input
                                type="text"
                                id="billing-address-line2"
                                className="billing-field"
                                style={customStyle}
                                placeholder="Address Line 2 (Optional)"
                            />
                        </div>
                    </div>
                    <div className="billing-row">
                        <div style={{ marginTop: 10 }}>
                            <input
                                type="text"
                                id="billing-city"
                                className="billing-field"
                                style={customStyle}
                                placeholder="City"
                                required={true}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <input
                                type="text"
                                id="billing-postal-code"
                                className="billing-field"
                                style={customStyle}
                                placeholder="Postal Code"
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="billing-row">
                        <div style={{ marginTop: 10, flex: "0 1 50%" }}>
                            <Select
                                options={countryRegionData.map((country) => ({
                                    value: country.countryShortCode,
                                    label: country.countryName,
                                }))}

                                value={countryRegionData
                                    .map((country) => ({
                                        value: country.countryShortCode,
                                        label: country.countryName,
                                    }))
                                    .find((option) => option.value === selectedCountry)}
                                onChange={handleCountryChange}
                                placeholder="Select Country"
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                    }),
                                }}
                            />
                        </div>
                        <div style={{ marginTop: 10, flex: "0 1 50%" }}>
                            <Select

                                options={regions.map((region) => ({
                                    value: region.shortCode,
                                    label: region.name,
                                }))}
                                value={regions
                                    .map((region) => ({
                                        value: region.shortCode,
                                        label: region.name,
                                    }))
                                    .find((option) => option.value === selectedState)}
                                onChange={handleStateChange}
                                placeholder="Select State/Region"
                                isDisabled={!regions.length}
                                styles={{
                                    container: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                    }),
                                }}
                            />
                            <input type={'hidden'} value={selectedCountry} id={'billing-country'}/>
                            <input type={'hidden'} value={selectedState} id={'billing-state'}/>
                        </div>
                    </div>
                </div>
            )}

            <button
                className="profile-update-btn"
                style={{ float: "right", marginTop: 10, height: 60, fontWeight: 600, fontSize: 20 }}
                onClick={handleClick}
            >
                {paying ? <Spinner animation="border" /> : "PLACE ORDER"}
            </button>
        </>
    );
};

function Checkout() {
    const [paypalToken, setPaypalToken] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showDs, setShowDs] = useState(true);

    const state = useSelector((state) => state)
    const token = useSelector(state => state.auth?.access_token)
    const history = useHistory()
    const dispatch = useDispatch()
    const executeRecaptcha = async (action) => {
        if (window.grecaptcha) {
            try {
                const recaptchaToken = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action});
                return recaptchaToken;
            } catch (error) {
                console.error("reCAPTCHA execution error:", error);
                return null;
            }
        }
        return null;
    };
    const handleCheckoutClick = async () => {
        const recaptchaToken = await executeRecaptcha('checkout');

        if (!recaptchaToken) {
            dispatch({
                type: "NOTIFICATE", payload: {
                    type: 'danger', message: 'reCAPTCHA verification failed.'
                }
            });
            return;
        }

        const data = JSON.stringify({
            UserId: state.auth.user_id,
            ShoppingCartId: state.cart.shoppingCartId,
            ShoppingCartItemIds: state.cart.selectedItems,
            token: recaptchaToken

        });
        setButtonLoading(true)
        axios.request({
            url: api.Cart.doCheckout.url, headers: {
                'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': token
            }, method: api.Cart.doCheckout.method, data: data,
        }).then(response => response.data).then(response => {
            if (response.isPaymentNeeded) {
                document.location.href = response.url
            } else {
                history.push('/Shop/Thanks/' + data.orderId + '/payed')

            }
            setButtonLoading(false)
        }).catch(err => {
            dispatch({
                type: "NOTIFICATE", payload: {
                    type: 'primary', message: 'Something went wrong'
                }
            })
        })
    }
    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;
        script.async = true;
        document.body.appendChild(script);
        executeRecaptcha('token').then(recaptchaToken => {
            axios.request({
                url: api.PayPal.token.url, headers: {
                    'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': token
                }, method: api.PayPal.token.method, data: {
                    token: recaptchaToken
                }
            }).then(response => {
                setShowDs(response.data?.enable3DS)
                setPaypalToken(response?.data?.token)
                setLoading(false)
            })
        })
    }, [token])
    return loading ? <div>loading</div> : <div className={"cart-container"}>
        <Header title={'Checkout'}/>
        <Row className={'cart-data-container mt-5 mb-5'}>
            <Col lg={{span: 4, offset: 4}}>
                <h3>Payment Method</h3>
                <PayPalScriptProvider
                    options={{
                        "client-id": process.env.REACT_APP_PAYPAL_CLIENT,
                        components: "buttons,hosted-fields",
                        "data-client-token": paypalToken,
                        intent: "capture",
                        vault: false,
                    }}
                >
                    <PayPalHostedFieldsProvider
                        styles={{
                            ".valid": {"color": "#28a745"}, ".invalid": {"color": "#dc3545"}, "input": {
                                "font-family": "monospace",

                            }
                        }}

                        createOrder={async () => {
                          let captchaToken =  await executeRecaptcha('order')
                                const billingAddress = showDs?{
                                    addressLine1: document.getElementById("billing-address-line1")?.value,
                                    addressLine2: document.getElementById("billing-address-line2")?.value || "",
                                    adminArea2: document.getElementById("billing-city")?.value,
                                    postalCode: document.getElementById("billing-postal-code")?.value,
                                    adminArea1: document.getElementById("billing-state")?.value,
                                    countryCode: document.getElementById("billing-country")?.value,
                                }:{};
                                const orderData = JSON.stringify({
                                    UserId: state.auth.user_id,
                                    ShoppingCartId: state.cart.shoppingCartId,
                                    ShoppingCartItemIds: state.cart.selectedItems,
                                    token: captchaToken,
                                    billingAddress,
                                })
                                return axios.request({
                                    url: api.PayPal.order.url, headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'Authorization': token
                                    }, method: api.PayPal.order.method, data: orderData
                                }).then(response => {
                                    return response.data.id;
                                })
                        }}
                    >
                        <button onClick={handleCheckoutClick} style={{
                            width: '100%',
                            marginTop: 10,
                            padding: 20,
                            backgroundColor: '#fabe32',
                            border: 'none',
                            borderRadius: 4,
                            color: '#fff'
                        }}>
                            {buttonLoading ? <Spinner animation="border"/> :
                                <img alt={'paypal'} src={PaypalButton}/>}</button>
                        <div style={{
                            marginTop: 10,
                            width: '100%',
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: 'center'
                        }}>
                            <div style={{width: "20%", margin: 11}}>
                                <div style={{borderBottom: "1px solid #adadaa",}}></div>
                            </div>
                            <div>Or</div>
                            <div style={{width: "20%", margin: 11}}>
                                <div style={{borderBottom: "1px solid #adadaa",}}></div>
                            </div>
                        </div>
                        <div style={{marginTop: 10}}>
                            <PayPalHostedField
                                id="card-number"
                                className="card-field"
                                style={CUSTOM_FIELD_STYLE}
                                hostedFieldType="number"
                                options={{
                                    selector: "#card-number", placeholder: "Card Number",
                                }}
                            />
                        </div>
                        <div style={{display: "flex", width: '100%', gap: 10, marginTop: 10}}>
                            <div style={{width: '100%'}}>
                                <PayPalHostedField
                                    id="expiration-date"
                                    className="card-field"
                                    style={CUSTOM_FIELD_STYLE}
                                    hostedFieldType="expirationDate"
                                    options={{
                                        selector: "#expiration-date", placeholder: "Expiration",
                                    }}
                                />
                            </div>
                            <div style={{width: '100%'}}>
                                <PayPalHostedField
                                    id="cvv"
                                    className="card-field"
                                    style={CUSTOM_FIELD_STYLE}
                                    hostedFieldType="cvv"
                                    options={{
                                        selector: "#cvv", placeholder: "CVV", maskInput: true,
                                    }}
                                />
                            </div>

                        </div>


                        <SubmitPayment showBilling={showDs} customStyle={CUSTOM_FIELD_STYLE}/>
                    </PayPalHostedFieldsProvider>
                </PayPalScriptProvider>
            </Col>
        </Row>
        <Footer/>
    </div>
}

export default Checkout
